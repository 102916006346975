<template>
  <div>
    <div class="edit_box  mrb20">
      <div class="size16">
        筛选查询
      </div>
      <div class="mrt20">
        <el-form ref="form" :model="selectForm" label-width="80px" class="flex">
          <el-form-item label="标题">
            <el-input v-model="selectForm.name" placeholder="请输入广告标题" style="width: 200px;" clearable></el-input>
          </el-form-item>
          <!-- <el-form-item label="内容">
            <el-input v-model="selectForm.name" placeholder="请输入广告内容" style="width: 200px;" clearable></el-input>
          </el-form-item> -->
          <el-form-item label="状态">
            <el-select v-model="selectForm.status" placeholder="请选择状态" clearable>
              <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="广告位置">
            <el-select v-model="selectForm.position_id" placeholder="请选择广告位置的ID" clearable>
              <el-option v-for="(item,i) in osition" :key="i" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="getList(Object.assign(selectForm,pageInfo))">查找</el-button>
            <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="edit()">新增</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="mrb20 bg-white pd20">
      <div class="mrb20">数据列表</div>
      <div>
        <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" style="width: 100%">
          <el-table-column label="序号" prop="id" align="center">
          </el-table-column>
          <el-table-column prop="name" label="广告标题" width="120" align="center">
          </el-table-column>
          <el-table-column prop="content" label="广告内容" width="120" align="center">
          </el-table-column>
          <el-table-column prop="cover" label="广告图片" width="120" align="center">
            <template slot-scope="scope">
              <el-image :preview-src-list="[scope.row.cover]" style="width: 100px; height: 100px" :src="scope.row.cover" fit="fill"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="background_image" label="广告背景" width="120" align="center">
            <template slot-scope="scope">
              <el-image :preview-src-list="[scope.row.background_image]" style="width: 100px; height: 100px" :src="scope.row.background_image" fit="fill"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="排序" width="120" align="center">
          </el-table-column>
          <el-table-column prop="position" label="广告位置" width="120" align="center">
            <template slot-scope="scope" v-if="scope.row.position">
              {{ scope.row.position.name  }}
            </template>
          </el-table-column>
          <el-table-column prop="url" label="跳转链接" width="120" align="center">
            <template slot-scope="scope">
              <a :href="scope.row.url" target="_blank">
                {{ scope.row.url }}
              </a>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="是否启用" width="100px">
            <template slot-scope="scope">
              <el-button v-if="scope.row.status == 1" type="success" plain size="mini">启用</el-button>
              <el-button type="info" plain size="mini" v-if="scope.row.status == 0">禁用</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="创建时间" width="160" align="center">
          </el-table-column>
          <el-table-column prop="updated_at" label="更新时间" width="160" align="center">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-button size="mini" icon="el-icon-edit" type="primary" @click="edit(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="handleDelete(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="mrt20 flex justify-end ">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pageInfo.page" :page-sizes="[10, 20, 50]" :page-size="pageInfo.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageInfo.count">
          </el-pagination>
        </div>

      </div>
    </div>

    <!-- 新增 -->
    <el-dialog title="广告" :visible.sync="originalVisible" width="40%">
      <el-form ref="originaForm" :model="originaForm" label-width="100px">
        <el-form-item label="广告标题" required>
          <el-input v-model="originaForm.name"></el-input>
        </el-form-item>
        <el-form-item label="广告内容" required>
          <el-input type="textarea" v-model="originaForm.content" :autosize="{ minRows: 4, maxRows: 6}"></el-input>
        </el-form-item>
        <el-form-item label="广告图片">
          <file-pic :file='originaForm.cover' @input='originaForm.cover = $event'></file-pic>
        </el-form-item>
        <el-form-item label="广告背景图片">
          <file-pic :file='originaForm.background_image' @input='originaForm.background_image = $event'></file-pic>
        </el-form-item>
        <el-form-item label="广告位置">
          <el-select v-model="originaForm.position_id" placeholder="请选择广告位置的ID" clearable>
            <el-option v-for="(item,i) in osition" :key="i" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转链接" required>
          <el-input v-model="originaForm.url"></el-input>
        </el-form-item>
        <el-form-item label="排序" required>
          <el-input v-model="originaForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="originaForm.status" placeholder="请选择状态" clearable>
            <el-option v-for="item in statusDetail" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="originalVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">{{ dialogTitle }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import filePic from "@/components/common/filePic";
import { statusOption, statusDetail } from "@/utils/statusData";
export default {
  components: { filePic },
  props: {},
  data() {
    return {
      statusOption,
      statusDetail,
      originalVisible: false,
      pageInfo: {
        limit: 10,
        page: 1,
        count: 0,
        pageNum: 0,
      },
      selectForm: {
        name: "",
        status: "",
        position_id: "",
      },
      // 新增
      originaForm: {
        id: "",
        name: "",
        content: "",
        cover: "",
        background_image: "",
        position_id: "",
        url: "",
        sort: "",
        status: "",
      },
      originalOption: [],
      osition: [],
      tableData: [],
      evaluatePictureList: "",
      ifMaterialEdit: 0, // 0表示编辑，1表示新增
      dialogTitle: "",
    };
  },

  created() {
    this.getList(this.pageInfo);
    this.originalList();
    this.getOsition();
  },
  mounted() {},
  computed: {},

  methods: {
    async originalList() {
      const { data } = await this.$Api.originalList();
      this.originalOption = data.list.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      // Object.assign(this.pageInfo, data);
    },
    // 新增编辑
    edit(row) {
      if (row) {
        // console.log("点击编辑", row);
        this.ifMaterialEdit = 0;
        this.originaForm.id = row.id;
        this.originaForm.position_id = row.position_id;

        this.originalVisible = true;
        this.dialogTitle = "编辑";
        this.originaForm = { ...row };
      } else {
        // console.log("新增咯");
        this.ifMaterialEdit = 1;
        this.originalVisible = true;
        this.dialogTitle = "新增";
        this.originaForm = {
          id: "",
          name: "",
          content: "",
          cover: "",
          background_image: "",
          position_id: "",
          url: "",
          sort: "",
          status: "",
        };
      }
    },
    async onSubmit() {
      // 新增接口对接
      if (this.ifMaterialEdit == 1) {
        // console.log("新增管理", this.originaForm);
        const data = await this.$Api.animeAdvEdit(this.originaForm);
        if (data.code !== 200) {
          this.$message.error(data.msg);
          return;
        }
        this.$message.success("新增成功");
        this.originalVisible = false;
        this.getList(this.pageInfo);
      } else if (this.ifMaterialEdit == 0) {
        // 编辑接口对接
        const data = await this.$Api.animeAdvEdit(this.originaForm);
        this.$message.success("编辑成功");
        this.originalVisible = false;
        this.getList(this.pageInfo);
      }
    },
    async getList(pageInfo) {
      const { data } = await this.$Api.animeAdv(pageInfo);
      this.tableData = data.list;
      // console.log("广告列表", data);
      this.pageInfo.limit = data.limit;
      this.pageInfo.page = data.page;
      this.pageInfo.count = data.count;
      this.pageInfo.pageNum = data.pageNum;
      // Object.assign(this.pageInfo, data);
      // console.log(data, "成功的原著列表");
    },
    handleSizeChange(val) {
      // console.log("val", val);
      this.pageInfo.limit = val;
      this.pageInfo.page = 1;
      this.getList(this.pageInfo);
    },
    handleCurrentChange(val) {
      // console.log("val", val);
      this.pageInfo.page = val;
      this.getList(this.pageInfo);
    },
    async getOsition() {
      const { data } = await this.$Api.animeAdvPosition();
      this.osition = data;
    },
    // 批量删除
    // batchdelete() {
    //   this.$confirm("确定要删除该商品信息吗?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       console.log("点击删除");
    //       return;
    //       this.$message({
    //         type: "success",
    //         message: "删除成功!",
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消删除",
    //       });
    //     });
    // },
    // 单个删除
    handleDelete(row) {
      this.$confirm("确定要删除该商品信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$Api.animeAdvDel({ ids: [row] });
          // console.log("删除成功", data);
          if (data.code != 200) {
            return this.$message({
              message: data.msg,
              type: "error",
            });
          }
          this.$message({
            message: data.msg,
            type: "success",
          });
          this.getList(this.pageInfo);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: data.msg,
          });
        });
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-tooltip__popper.is-dark {
  width: 700px !important;
}
.edit_box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
}
</style>